<template>
  <div>
    <div class="header"><span class="ret" @click="ret">开票管理/</span> <span>开票详情</span></div>
    <ul class="content" v-if="ticketInfo">
      <li class="bg">
        <span class="left">发票单详情</span>
      </li>
      <li>
        <span class="left">申请单编号</span>
        <span class="right">{{ticketInfo.id}}</span>
      </li>
      <li>
        <span class="left">申请时间</span>
        <span class="right">{{ticketInfo.createTime}}</span>
      </li>
      <li>
        <span class="left">开票金额合计</span>
        <span class="right">{{ticketInfo.applyAmount}}元</span>
      </li>
      <!-- <li>
        <span class="left">任务订单</span>
        <span class="right">4个 <span class="operation" @click="check">点击查看</span></span>
      </li> -->
      <li class="border-bottom">
        <span class="left">申请单状态</span>
        <span class="right" v-if="ticketInfo.status==='102801'">已申请</span>
        <span class="right" v-else-if="ticketInfo.status==='102811'">处理中</span>
        <span class="right" v-else>已寄送</span>
      </li>
    </ul>
    <ul class="content" v-if="ticketInfo">
      <li>
        <span class="left">发票类型</span>
        <span class="right" v-if="ticketInfo.invoiceType==='10'">普票</span>
        <span class="right" v-else>专票</span>
      </li>
      <li>
        <span class="left">发票总数</span>
        <span class="right">{{ticketInfo.invoiceNum===null?'--':ticketInfo.invoiceNum}}张</span>
      </li>
      <li>
        <span class="left">发票抬头</span>
        <span class="right">{{ticketInfo.invoiceName}}</span>
      </li>
      <!-- <li>
        <span class="left">发票内容</span>
        <span class="right">选择发发票内容暂时在这里</span>
      </li> -->
      <li>
        <span class="left">纳税人识别码</span>
        <span class="right">{{ticketInfo.creditCode}}</span>
      </li>
      <li v-if="ticketInfo.invoiceType==='20'">
        <span class="left">收票人地址</span>
        <span class="right">{{ticketInfo.contactsAddress}}</span>
      </li>
      <li v-if="ticketInfo.invoiceType==='20'">
        <span class="left">收票人姓名</span>
        <span class="right">{{ticketInfo.contactsName}}</span>
      </li>
      <li v-if="ticketInfo.invoiceType==='20'">
        <span class="left">联系电话</span>
        <span class="right">{{ticketInfo.contactsPhone}}</span>
      </li>
      <li v-if="ticketInfo.invoiceType==='10'">
        <span class="left">邮寄邮箱</span>
        <span class="right">{{ticketInfo.sendEmail}}</span>
      </li>
      <li v-if="ticketInfo.invoiceType==='20'">
        <span class="left">邮寄单号</span>
        <span class="right">{{ticketInfo.logisticsCode===null?'--':ticketInfo.logisticsCode}}</span>
      </li>
      <li v-if="ticketInfo.invoiceType==='20'">
        <span class="left">邮寄公司</span>
        <span class="right">{{ticketInfo.logisticsCompany===null?'--':ticketInfo.logisticsCompany==='100101'?'京东':ticketInfo.logisticsCompany==='100102'?'顺丰':ticketInfo.logisticsCompany==='100103'?'德邦':'--'}}</span>
      </li>
      <li class="border-bottom">
        <span class="left">订单备注</span>
        <span class="right">{{ticketInfo.remark}}</span>
      </li>
    </ul>
    <a-modal
      :title="title"
      :visible="visible"
      @cancel="handleCancel"
      :footer="null"
      width="850px"
    >
      <ul class="models">
        <li>
          <span class="left">【1】任务单号：SF1512122121212</span>
          <span class="right">任务总额：50000000.00元</span>
        </li>
        <li>
          <span class="left">【2】任务单号：SF1512122121212</span>
          <span class="right">任务总额：50000000.00元</span>
        </li>
        <li>
          <span class="left">【3】任务单号：SF1512122121212</span>
          <span class="right">任务总额：50000000.00元</span>
        </li>
        <li>
          <span class="left">【4】任务单号：SF1512122121212</span>
          <span class="right">任务总额：50000000.00元</span>
        </li>
      </ul>
      <div class="mg-24"><a-button type="primary" @click="handleCancel">关闭</a-button></div>
    </a-modal>
    <div class="mg-24">
      <a-button type="primary" @click="ret">返回</a-button>
    </div>
  </div>
</template>
<script>
import {
  getApplyInvoiceTradeDetails
} from '@/api/api'
export default {
  data() {
    return {
      visible: false,
      title: '订单任务总数：4个',
      ticketInfo: null
    };
  },
  created () {
    let id = JSON.parse(window.sessionStorage.getItem('ticketInfo')).invoiceId
    getApplyInvoiceTradeDetails(id).then(res => {
      if (res.success) {
        this.ticketInfo = res.result
      }
    })
  },
  methods: {
    // 返回开票
    ret () {
      window.sessionStorage.setItem('submenu','kkfp')
      this.$router.push('/kkfp')
    },
    // 取消
    handleCancel () {
      this.visible = false
    },
    // 查看任务订单
    check () {
      this.visible = true
    }
  }
}
</script>
<style scoped lang="less">
.header{
  color: #000000;
  font-size: 14px;
  margin-bottom: 20px;
  .ret{
    color: rgba(0,0,0,0.65);
    cursor: pointer;
  }
}
.content{
  list-style: none;
  padding: 0;
  margin-bottom: 24px;
  li{
    display: flex;
    border-top: 1px solid #F0F0F0;
    .left{
      width: 300px;
      height: 47px;
      padding: 16px 25px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      border-left: 1px solid #F0F0F0;
    }
    .right{
      width: 100%;
      height: 47px;
      padding: 16px 25px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      border-left: 1px solid #F0F0F0;
      border-right: 1px solid #F0F0F0;
      .operation{
        color: #1890FF;
        margin-left: 23px;
        cursor: pointer;
      }
    }
  }
  .bg{
    background: #FAFAFA;
    border-right: 1px solid #F0F0F0;
    font-weight: bold;
  }
  .border-bottom{
    border-bottom: 1px solid #F0F0F0;
  }
}
.mg-24{
  margin-top: 24px;
  text-align: center;
}
.models{
  width: 800px;
  padding: 0;
  list-style: none;
  border-right: 1px solid #F0F0F0;
  border-bottom: 1px solid#F0F0F0;
  li{
    display: flex;
    .left,.right{
      width: 400px;
      height: 48px;
      padding: 16px 33px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      border-top: 1px solid #F0F0F0;
      border-left: 1px solid#F0F0F0;
    }
  }
}
</style>